'use client'
import classNames from "classnames";
import styles from '../styles.module.scss'
import cargo from '../images/cargo.webp';
import delivery from '../images/delivery.webp';
import passenger from '../images/passenger.webp';
import rent from '../images/rent.webp';
import cargoSmall from '../images/cargo-small.webp';
import deliverySmall from '../images/delivery-small.webp';
import passengerSmall from '../images/passenger-small.webp';
import rentSmall from '../images/rent-small.webp';
import {useEffect, useRef, useState} from "react";
import {useResizeWindow} from "@/hooks/useResizeWindow";
import {RedirectLink} from "@/ui/RedirectLink/RedirectLink";
import {APP_URL} from "@/CONSTANTS";
import {RoleType} from "@/interfaces/RoleType";


interface IProps {
    taskType: {
        tags: string[]
        type: string
        title: string
        description: string
    }
    roleType: RoleType
}

const getImageSrc = (type: string, width: number) => {
    if (width > 740) {
        if (type === 'cargo') return cargo.src;
        if (type === 'rent') return rent.src;
        if (type === 'passenger') return passenger.src;
        if (type === 'delivery') return delivery.src;
    } else {
        if (type === 'cargo') return cargoSmall.src;
        if (type === 'rent') return rentSmall.src;
        if (type === 'passenger') return passengerSmall.src;
        if (type === 'delivery') return deliverySmall.src;
    }
    return '';
};

export const TaskTypeCard = ({taskType, roleType}: IProps) => {
    const [isFocus, setIsFocus] = useState(false)
    const [isDown, setIsDown] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)

    const [isClient, setIsClient] = useState(false);

    const width = useResizeWindow()

    const containerRef = useRef<HTMLDivElement | null>(null)

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDown(true)
        setStartX(e.pageX - (containerRef.current?.offsetLeft || 0))
        setScrollLeft(containerRef.current?.scrollLeft || 0)
    }

    const handleMouseUp = () => {
        setIsDown(false)
    }

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - (containerRef.current?.offsetLeft || 0)
        const walk = x - startX
        if (containerRef.current) {
            containerRef.current.scrollLeft = scrollLeft - walk
        }
    }

    useEffect(() => {
        setIsClient(true);
    }, []);


    return (
        <div
            className={classNames(styles.card)}
            onMouseOver={() => setIsFocus(true)}
            onMouseLeave={() => setIsFocus(false)}
            style={isClient ? {backgroundImage: `url(${getImageSrc(taskType.type, width)})`} : {}}
        >

            <p
                className={classNames(styles.title, isClient && (isFocus || (width && width < 1240)) ? styles.focus : '')}>{taskType.title}
            </p>

            <div
                className={classNames(styles.focusBlock, isClient && (isFocus || (width && width < 1240)) ? styles.focus : '')}>
                <div className={classNames(styles.focusBlockInfo)}>
                    <div className={classNames(styles.line)}/>
                    <p className={classNames(styles.description)}>{taskType.description}</p>
                </div>

            </div>
            <div
                className={classNames(styles.tagsWrapper, isClient && (isFocus || (width && width < 1240)) ? styles.focusTags : '')}
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
            >
                {taskType.tags.map((tag, index) => <RedirectLink key={index} href={`${APP_URL}/sign-up`}
                                                                 roleType={roleType}
                                                                 className={classNames(styles.tag)} text={tag}/>)}
            </div>

        </div>
    )
}
