'use client'
import s from './styles.module.scss'
import Image from 'next/image'
import logo from '@/images/logo.png'
import {Navigation} from '../Navigation'
import {useEffect, useState} from 'react'
import {Login} from '@/ui/Header/Login'
import {CookieAcceptPopup} from '@/ui/CookieAcceptPopup'
import {PageType} from '@/interfaces/PageType'
import {navigationItems} from '@/CONSTANTS'
import {UpButton} from '@/ui/UpButton'
import Link from 'next/link'
import {RoleTypeExtended} from "@/interfaces/RoleType";
import classNames from "classnames";

interface IProps {
    roleType: RoleTypeExtended
    pageType?: PageType
}

export const Header = ({roleType, pageType}: IProps) => {

    const [isMenuOpened, setIsMenuOpened] = useState(false)

    useEffect(() => {
        document.body.classList.toggle('hidden', isMenuOpened)
    }, [isMenuOpened])

    const toggleMenu = () => {
        setIsMenuOpened(prev => !prev)
    }

    const [isPopupOpened, setIsPopupOpened] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('cap')) { //cookie accept popup
            setTimeout(() => {
                setIsPopupOpened(true)
            }, 2000)
        }
    }, [])

    const handleClosePopup = () => {
        setIsPopupOpened(false)
        localStorage.setItem('cap', 'true')
    }

    return <header className={s.wrapper}>
        <UpButton roleType={roleType}/>
        {isPopupOpened && <CookieAcceptPopup handleClose={handleClosePopup}/>}
        {isMenuOpened && <div className={`${s['mobile-menu-wrapper']} ${s.mobile}`}>
            <div className={s['mobile-menu-inner']}>
                <Navigation navigationItems={navigationItems} pageType={pageType}/>
                <Login roleType={roleType} colorType={'customer'}/>
            </div>
        </div>}

        <div className={'container'}>

            <div className={s.mobile}>
                <div className={s.inner}>
                    <Link href={'/'}>
                        <Image loading={"lazy"} src={logo.src} width={160} height={55} alt={'Логотип Veresking'}/>
                    </Link>

                    <div className={`${s.hamburger} ${isMenuOpened ? s.opened : s.closed}`}>
                        <button aria-label="toggleMenu" onClick={toggleMenu}/>
                    </div>
                </div>

                <a href={'tel:88007004062'}
                   className={classNames(s.phoneLink, isMenuOpened ? s.phoneLinkMenu : s.phoneLinkMobile)}>
                    <Image loading={"lazy"} src={`/assets/phone.svg`} width={32} height={32} alt={'phone'}/>
                    <span>8 (800) 700-40-62</span>
                </a>
            </div>

            <div className={s.desktop}>
                <div className={s.inner}>
                    <Link href={'/'}>
                        <Image loading={"lazy"} src={logo.src} width={200} height={68} alt={'Логотип Veresking'}/>
                    </Link>

                    <a href={'tel:88007004062'} className={s.phoneLink}>
                        <Image  loading={"lazy"} src={`/assets/phone.svg`} width={32} height={32} alt={'phone'}/>
                        <span>
                8 (800) 700-40-62
              </span>
                    </a>

                    <div className={s['login-wrapper']}>
                        <Login roleType={roleType}/>
                    </div>
                </div>
                <Navigation navigationItems={navigationItems} pageType={pageType}/>
            </div>
        </div>
    </header>
}
