'use client'
import { useEffect, useRef } from 'react';
import { useResizeWindow } from '@/hooks/useResizeWindow'

const BitrixForm = () => {
  const formRef = useRef<HTMLDivElement | null>(null);

  const deviceWidth = useResizeWindow()
  const isMobile = deviceWidth < 900

  useEffect(() => {
    if (typeof window !== 'undefined' && formRef.current) {
      const formContainer = formRef.current;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        (function(w,d,u){
          var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
          var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn-ru.bitrix24.ru/b28284744/crm/form/loader_10.js');
      `;

      formContainer.appendChild(script);
    }
  }, []);

  return (
    <div ref={formRef} style={{ width: '100%', maxWidth: 420, height: '100%', boxShadow: !isMobile ? '0 4px 18px 0 rgba(0,0,0,.3)' : 'none', borderRadius: 15 }}>
          <script data-b24-form="inline/10/sshph0" data-skip-moving="true"></script>
    </div>
  );
};

export default BitrixForm;