export const declensionAfterNumerals = (
    number: number,
    wordForms: [string, string, string] //1 яблоко, 2 яблока, 5 яблок
) => {
    const n = Math.abs(number) % 100
    const n1 = n % 10
    if (n > 10 && n < 20) {
        return wordForms[2]
    }
    if (n1 > 1 && n1 < 5) {
        return wordForms[1]
    }
    if (n1 == 1) {
        return wordForms[0]
    }
    return wordForms[2]
}
