export const beginningArray = [
  {
  title: 'Регистрация',
  description: 'Стандартная регистрация и подтверждение личности откроет доступ к функционалу платформы.'
  },
  {
    title: 'Добавить задачу',
    description: 'В личном кабинете нажмите на кнопку «Создать заявку». Используйте готовый шаблон объявления для быстрого поиска исполнителя.'
  },
  {
    title: 'Получить предложение',
    description: 'Просматривайте отклики исполнителей через личный кабинет.'
  },
  {
    title: 'Выбрать исполнителя',
    description: 'Обсуждайте детали сотрудничества через онлайн-чат и назначайте подходящего исполнителя на задачу.'
  },
  {
    title: 'Принять работы',
    description: 'Принимайте работы на объекте и отмечайте завершение сотрудничества на платформе через кнопку «Завершение задачи».'
  },
]


export const executorBeginningArray = [
  {
    title: 'Регистрация',
    description: 'Простая регистрация и подтверждение личности в роли исполнителя открывает весь функционал платформы.'
  },
  {
    title: 'Добавить технику',
    description: 'Добавление каждой единицы техники расширяет ваш функционал и повышает количество возможных заказов.'
  },
  {
    title: 'Поиск задач',
    description: 'Точные параметры работ, которые может выполнять ваша техника, помогут подобрать максимально подходящие задачи.'
  },
  {
    title: 'Откликнуться на задачи',
    description: 'Количество откликов за день не ограничено. Чем больше заказчиков знает о вашей готовности работать, тем выше шансы получить самые прибыльные заказы.'
  },
  {
    title: 'Начать работу',
    description: 'Как только заказчик выберет вас в роли исполнителя, вы можете приступать к работе'
  },
]