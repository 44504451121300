'use client'
import s from './styles.module.scss'
import { useResizeWindow } from '@/hooks/useResizeWindow'
import Image from 'next/image'
import prizeDesktop from '@/images/PrizeBanner/prize-desktop.png'
import prizeMobile from '@/images/PrizeBanner/prize-mobile.png'
import {useEffect, useState} from "react";

interface IProps {
  roleType: 'executor' | 'customer'
}

export const Decore = ({ roleType }: IProps) => {
  const [isClient, setIsClient] = useState(false)

  const windowWidth = useResizeWindow()
  const isMobile = windowWidth < 900

  useEffect(() => {
    setIsClient(true)
  }, [])

  return <div className={`${s.wrapper} ${isClient ? isMobile ? s.mobile : s.desktop : ''} ${s[roleType]}`}>
    {isMobile ? <Image loading={"lazy"} src={isClient ? prizeMobile : ''} alt={'Приз розыгрыша'} width={isClient ? 700 : 620} height={isClient ? 243 : 404} /> :
      <Image loading={"lazy"} src={isClient ? prizeDesktop : ''} alt={'Приз розыгрыша'} width={620} height={404} />}
  </div>
}
