export const vehiclesTypes = [
    'Гусеничный экскаватор',
    'Вилочный погрузчик',
    'Автогрейдер',
    'Телескопический погрузчик',
    'Стреловой подъемник',
    'Ямобур',
    'Автокаток',
    'Минипогрузчик',
    'Экскаватор-погрузчик',
    'Автобетоносмеситель',
    'Башенный кран',
    'Автокран',
    'Манипулятор',
    'Самосвал 40т',
    'Карьерный самосвал',
    'Самосвал 10т',
    'Тонар',
    'Гусеничный экскаватор',
    'Гусеничный минипогрузчик',
    'Бульдозер'
]

const handleConvertTime = (minutes: number) => {
    let currentDate = new Date();

    currentDate.setMinutes(currentDate.getMinutes() - minutes);

    return currentDate.getTime()
}

export const mockVehiclesPage1 = [
    {
        id: 1,
        title: 'Самосвал',
        model: 'SHACMAN 8X4 X3000',
        workTypes: 'Грузоперевозка/Доставка материала',
        capacity: '40т',
        volume: '26м3',
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(0))}`,
    },
    {
        id: 2,
        title: 'АГП 18м',
        model: 'МАЗ',
        workTypes: 'Аренда спецтехники',
        capacity: '300кг',
        volume: null,
        liftingHeight: '18м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(1))}`,
    },
    {
        id: 3,
        title: 'Экскаватор-погрузчик',
        model: 'Блюминг БЛ-750-01(Беларус)',
        workTypes: 'Аренда спецтехники',
        capacity: '1,5т',
        volume: '1м3',
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(2))}`,
    },
    {
        id: 4,
        title: 'Каток дорожный',
        model: 'XCMG XMR303',
        workTypes: 'Аренда спецтехники',
        capacity: '3т',
        volume: null,
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(3))}`,
    },
    {
        id: 5,
        title: 'Самосвал',
        model: 'МАЗ 6516',
        workTypes: 'Грузоперевозка/Доставка материала',
        capacity: '30т',
        volume: '23м3',
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(3))}`,
    },
    {
        id: 6,
        title: 'Самосвал',
        model: 'КАМАЗ 65115',
        workTypes: 'Грузоперевозка/Доставка материала',
        capacity: '15т',
        volume: '10м3',
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(3))}`,
    },
    {
        id: 7,
        title: 'Бульдозер',
        model: 'Б10М',
        workTypes: 'Аренда спецтехники',
        capacity: '19,5 т',
        volume: null,
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(5))}`,
    },
    {
        id: 8,
        title: 'Автокран 50т',
        model: 'Ивановец КС-6973А',
        workTypes: 'Аренда спецтехники',
        capacity: '50т',
        volume: null,
        liftingHeight: '31м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(7))}`,
    },
]

export const mockVehiclesPage2 = [
    {
        id: 9,
        title: 'Экскаватор-погрузчик',
        model: 'Case 570ST',
        workTypes: 'Аренда спецтехники',
        capacity: '1,5т',
        volume: '1,0м3',
        liftingHeight: '4,3м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(7))}`,
    },
    {
        id: 10,
        title: 'Автокран 25т',
        model: 'XCMG QY25K',
        workTypes: 'Аренда спецтехники',
        capacity: '25т',
        volume: null,
        liftingHeight: '32.5м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(8))}`,
    },
    {
        id: 11,
        title: 'Манипулятор 3т',
        model: 'Бортовой Камаз 65117 с кму UNIC V-503 г/п 3т.',
        workTypes: 'Грузоперевозка/Доставка материала',
        capacity: '3т',
        volume: null,
        liftingHeight: '8м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(8))}`,
    },
    {
        id: 12,
        title: 'Минипогрузчик',
        model: 'Bobcat S530',
        workTypes: 'Аренда спецтехники',
        capacity: '980кг',
        volume: '0,49м3',
        liftingHeight: '3м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(10))}`,
    },
    {
        id: 13,
        title: 'Миниэкскаватор',
        model: 'Hitachi ex30ur-2c',
        workTypes: 'Аренда спецтехники',
        capacity: null,
        volume: '0,03м3',
        liftingHeight: '2м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(11))}`,
    },
    {
        id: 14,
        title: 'Фронтальный погрузчик',
        model: 'Redstar ZL20S',
        workTypes: 'Аренда спецтехники',
        capacity: '1500кг',
        volume: '1,3м3',
        liftingHeight: '3,6м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(13))}`,
    },
    {
        id: 15,
        title: 'Телескопический погрузчик',
        model: 'Faresin 08.40 Classic 75',
        workTypes: 'Аренда спецтехники',
        capacity: '4т',
        volume: null,
        liftingHeight: '8м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(15))}`
    },
    {
        id: 16,
        title: 'Тонар',
        model: 'КАМАЗ+NEFAZ 9509 ',
        workTypes: 'Грузоперевозка/Доставка материала',
        capacity: '40т',
        volume: '26м3',
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(16))}`,
    },
]

export const mockVehiclesPage3 = [
    {
        id: 17,
        title: 'Колесный экскаватор',
        model: 'Hyundai ROBEX 1400W-7',
        workTypes: 'Аренда спецтехники',
        capacity: null,
        volume: '0,65м3',
        liftingHeight: '8,5м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(20))}`,
    },
    {
        id: 18,
        title: 'Минипогрузчик',
        model: 'Bobcat S530',
        workTypes: 'Аренда спецтехники',
        capacity: '980кг',
        volume: '0,49м3',
        liftingHeight: '3м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(21))}`,
    },
    {
        id: 19,
        title: 'Экскаватор-погрузчик',
        model: 'New Holland B115B РАВНОКОЛЕСНЫЙ',
        workTypes: 'Аренда спецтехники',
        capacity: '3,5т',
        volume: '1,2м3',
        liftingHeight: '6м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(22))}`,
    },
    {
        id: 20,
        title: 'Самосвал',
        model: 'Shacman SX32586T385 X3000',
        workTypes: 'Грузоперевозки/Доставка материала',
        capacity: '40т',
        volume: '20м3',
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(23))}`,
    },
    {
        id: 21,
        title: 'Колесный экскаватор',
        model: 'JCB JS 175',
        workTypes: 'Аренда спецтехники',
        capacity: null,
        volume: '0.90м3',
        liftingHeight: '6,5м',
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(23))}`,
    },
    {
        id: 22,
        title: 'Автогрейдер ',
        model: 'ДЗ 143',
        workTypes: 'Аренда спецтехники',
        capacity: '12,5 т',
        volume: null,
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(27))}`,
    },
    {
        id: 23,
        title: 'Микроавтобус',
        model: 'Mercedes-Benz Sprinter',
        workTypes: 'Пассажироперевозки ',
        capacity: null,
        volume: null,
        liftingHeight: null,
        capacityPeople: '19 человек',
        sideLength: null,
        time: `${(handleConvertTime(27))}`,
    },
    {
        id: 24,
        title: 'Ломовоз',
        model: 'КАМАЗ 53212 ',
        workTypes: 'Аренда спецтехники/Грузоперевозки',
        capacity: '10т',
        volume: '28м3',
        liftingHeight: null,
        capacityPeople: null,
        sideLength: null,
        time: `${(handleConvertTime(30))}`,
    },
]

// export const  mockVehicles = [
//   {
//     id: 1,
//     title: 'Самосвал',
//     model: 'SHACMAN 8X4 X3000',
//     workTypes: 'Грузоперевозка/Доставка материала',
//     capacity: '40т',
//     volume: '26м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(0))}`,
//     image: vehicles1
//   },
//   {
//     id: 2,
//     title: 'АГП 18м',
//     model: 'МАЗ',
//     workTypes: 'Аренда спецтехники',
//     capacity: '300кг',
//     volume: null,
//     liftingHeight: '18м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(1))}`,
//     image: vehicles2
//   },
//   {
//     id: 3,
//     title: 'Экскаватор-погрузчик',
//     model: 'Блюминг БЛ-750-01(Беларус)',
//     workTypes: 'Аренда спецтехники',
//     capacity: '1,5т',
//     volume: '1м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(2))}`,
//     image: vehicles3
//   },
//   {
//     id: 4,
//     title: 'Каток дорожный',
//     model: 'XCMG XMR303',
//     workTypes: 'Аренда спецтехники',
//     capacity: '3т',
//     volume: null,
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(3))}`,
//     image: vehicles4
//   },
//   {
//     id: 5,
//     title: 'Самосвал',
//     model: 'МАЗ 6516',
//     workTypes: 'Грузоперевозка/Доставка материала',
//     capacity: '30т',
//     volume: '23м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(3))}`,
//     image: vehicles5
//   },
//   {
//     id: 6,
//     title: 'Самосвал',
//     model: 'КАМАЗ 65115',
//     workTypes: 'Грузоперевозка/Доставка материала',
//     capacity: '15т',
//     volume: '10м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(3))}`,
//     image: vehicles6
//   },
//   {
//     id: 7,
//     title: 'Бульдозер',
//     model: 'Б10М',
//     workTypes: 'Аренда спецтехники',
//     capacity: '19,5 т',
//     volume: null,
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(5))}`,
//     image: vehicles7
//   },
//   {
//     id: 8,
//     title: 'Автокран 50т',
//     model: 'Ивановец КС-6973А',
//     workTypes: 'Аренда спецтехники',
//     capacity: '50т',
//     volume: null,
//     liftingHeight: '31м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(7))}`,
//     image: vehicles8
//   },
//   {
//     id: 9,
//     title: 'Экскаватор-погрузчик',
//     model: 'Case 570ST',
//     workTypes: 'Аренда спецтехники',
//     capacity: '1,5т',
//     volume: '1,0м3',
//     liftingHeight: '4,3м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(7))}`,
//     image: vehicles9
//   },
//   {
//     id: 10,
//     title: 'Автокран 25т',
//     model: 'XCMG QY25K',
//     workTypes: 'Аренда спецтехники',
//     capacity: '25т',
//     volume: null,
//     liftingHeight: '32.5м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(8))}`,
//     image: vehicles10
//   },
//   {
//     id: 11,
//     title: 'Манипулятор 3т',
//     model: 'Бортовой Камаз 65117 с кму UNIC V-503 г/п 3т.',
//     workTypes: 'Грузоперевозка/Доставка материала',
//     capacity: '3т',
//     volume: null,
//     liftingHeight: '8м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(8))}`,
//     image: vehicles11
//   },
//   {
//     id: 12,
//     title: 'Минипогрузчик',
//     model: 'Bobcat S530',
//     workTypes: 'Аренда спецтехники',
//     capacity: '980кг',
//     volume: '0,49м3',
//     liftingHeight: '3м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(10))}`,
//     image: vehicles12
//   },
//   {
//     id: 13,
//     title: 'Миниэкскаватор',
//     model: 'Hitachi ex30ur-2c',
//     workTypes: 'Аренда спецтехники',
//     capacity: null,
//     volume: '0,03м3',
//     liftingHeight: '2м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(11))}`,
//     image: vehicles13
//   },
//   {
//     id: 14,
//     title: 'Фронтальный погрузчик',
//     model: 'Redstar ZL20S',
//     workTypes: 'Аренда спецтехники',
//     capacity: '1500кг',
//     volume: '1,3м3',
//     liftingHeight: '3,6м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(13))}`,
//     image: vehicles14
//   },
//   {
//     id: 15,
//     title: 'Телескопический погрузчик',
//     model: 'Faresin 08.40 Classic 75',
//     workTypes: 'Аренда спецтехники',
//     capacity: '4т',
//     volume: null,
//     liftingHeight: '8м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(15))}`,
//     image: vehicles15
//   },
//   {
//     id: 16,
//     title: 'Тонар',
//     model: 'КАМАЗ+NEFAZ 9509 ',
//     workTypes: 'Грузоперевозка/Доставка материала',
//     capacity: '40т',
//     volume: '26м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(16))}`,
//     image: vehicles16
//   },
//   {
//     id: 17,
//     title: 'Колесный экскаватор',
//     model: 'Hyundai ROBEX 1400W-7',
//     workTypes: 'Аренда спецтехники',
//     capacity: null,
//     volume: '0,65м3',
//     liftingHeight: '8,5м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(20))}`,
//     image: vehicles17
//   },
//   {
//     id: 18,
//     title: 'Минипогрузчик',
//     model: 'Bobcat S530',
//     workTypes: 'Аренда спецтехники',
//     capacity: '980кг',
//     volume: '0,49м3',
//     liftingHeight: '3м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(21))}`,
//     image: vehicles18
//   },
//   {
//     id: 19,
//     title: 'Экскаватор-погрузчик',
//     model: 'New Holland B115B РАВНОКОЛЕСНЫЙ',
//     workTypes: 'Аренда спецтехники',
//     capacity: '3,5т',
//     volume: '1,2м3',
//     liftingHeight: '6м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(22))}`,
//     image: vehicles19
//   },
//   {
//     id: 20,
//     title: 'Самосвал',
//     model: 'Shacman SX32586T385 X3000',
//     workTypes: 'Грузоперевозки/Доставка материала',
//     capacity: '40т',
//     volume: '20м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(23))}`,
//     image: vehicles20
//   },
//   {
//     id: 21,
//     title: 'Колесный экскаватор',
//     model: 'JCB JS 175',
//     workTypes: 'Аренда спецтехники',
//     capacity: null,
//     volume: '0.90м3',
//     liftingHeight: '6,5м',
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(23))}`,
//     image: vehicles21
//   },
//   {
//     id: 22,
//     title: 'Автогрейдер ',
//     model: 'ДЗ 143',
//     workTypes: 'Аренда спецтехники',
//     capacity: '12,5 т',
//     volume: null,
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     time: `${(handleConvertTime(27))}`,
//     image: vehicles22
//   },
//   {
//     id: 23,
//     title: 'Микроавтобус',
//     model: 'Mercedes-Benz Sprinter',
//     workTypes: 'Пассажироперевозки ',
//     capacity: null,
//     volume: null,
//     liftingHeight: null,
//     capacityPeople: '19 человек',
//     sideLength: null,
//     image: vehicles23,
//     time: `${(handleConvertTime(27))}`,
//   },
//   {
//     id: 24,
//     title: 'Ломовоз',
//     model: 'КАМАЗ 53212 ',
//     workTypes: 'Аренда спецтехники/Грузоперевозки',
//     capacity: '10т',
//     volume: '28м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles24,
//     time: `${(handleConvertTime(30))}`,
//   },
//   {
//     id: 25,
//     title: 'Самосвал',
//     model: 'ГАЗ-3310 «Валдай»',
//     workTypes: 'Грузоперевозки/Доставка материала',
//     capacity: '3т',
//     volume: '5м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles25,
//     time: `${(handleConvertTime(33))}`,
//   },
//   {
//     id: 26,
//     title: 'Минипогрузчик',
//     model: 'ANT 1000',
//     workTypes: 'Аренда спецтехники',
//     capacity: '1т',
//     volume: '0,49м3',
//     liftingHeight: '3м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles26,
//     time: `${(handleConvertTime(37))}`,
//   },
//   {
//     id: 27,
//     title: 'Автокран 32т',
//     model: 'КС-55729-5B «ГАЛИЧАНИН» КАМАЗ 63501',
//     workTypes: 'Аренда спецтехники',
//     capacity: '32т',
//     volume: null,
//     liftingHeight: '31м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles27,
//     time: `${(handleConvertTime(37))}`,
//   },
//   {
//     id: 28,
//     title:'Экскаватор-погрузчик',
//     model: 'JCB 3CX',
//     workTypes: 'Аренда спецтехники',
//     capacity: '2т',
//     volume: '1м3',
//     liftingHeight: '4м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles28,
//     time: `${(handleConvertTime(38))}`
//   },
//   {
//     id: 29,
//     title:'АГП 22м',
//     model: 'АГП-22 ЗИЛ-433362',
//     workTypes: 'Аренда спецтехники',
//     capacity: '300кг',
//     volume: null,
//     liftingHeight: '22м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles29,
//     time: `${(handleConvertTime(39))}`
//   },
//   {
//     id: 30,
//     title: 'Длинномер',
//     model: 'МАЗ 543203-2122',
//     workTypes: 'Грузоперевозки/Доставка материала',
//     capacity: '20т',
//     volume: null,
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: '12м',
//     image: vehicles30,
//     time: `${(handleConvertTime(41))}`
//   },
//   {
//     id: 31,
//     title: 'Минипогрузчик',
//     model: 'Bobcat S530',
//     workTypes: 'Аренда спецтехники',
//     capacity: '980кг',
//     volume: '0,49м3',
//     liftingHeight: '3м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles31,
//     time: `${(handleConvertTime(43))}`
//   },
//   {
//     id: 32,
//     title: 'Экскаватор-погрузчик',
//     model: 'JCB 3CX',
//     workTypes: 'Аренда спецтехники',
//     capacity: '2т',
//     volume: '1м3',
//     liftingHeight: '4м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles32,
//     time: `${(handleConvertTime(43))}`
//   },
//   {
//     id: 33,
//     title: 'Манипулятор 3т',
//     model: 'Isuzu UNIC UR-V340',
//     workTypes: 'Аренда спецтехники/Грузоперевозки',
//     capacity: '3т',
//     volume: null,
//     liftingHeight: '8м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles33,
//     time: `${(handleConvertTime(45))}`
//   },
//   {
//     id: 34,
//     title: 'Фронтальный погрузчик',
//     model: 'CAT 950 GII',
//     workTypes: 'Аренда спецтехники',
//     capacity: null,
//     volume: '2м3',
//     liftingHeight: '4м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles34,
//     time: `${(handleConvertTime(46))}`
//   },
//   {
//     id: 35,
//     title: 'Самосвал',
//     model: 'КамАЗ 55111',
//     workTypes: 'Грузоперевозки/Доставка материала',
//     capacity: '10т',
//     volume: '8м3',
//     liftingHeight: null,
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles35,
//     time: `${(handleConvertTime(47))}`
//   },
//   {
//     id: 36,
//     title: 'Гусеничный экскаватор',
//     model: 'Hitachi ZX200-3',
//     workTypes: 'Аренда спецтехники',
//     capacity: null,
//     volume: '1,2м3',
//     liftingHeight: '6м',
//     capacityPeople: null,
//     sideLength: null,
//     image: vehicles36,
//     time: `${(handleConvertTime(50))}`
//   },
// ]