'use client'

import classNames from "classnames";
import styles from "@/sections/NewTasks/styles.module.scss";
import {Title} from "@/ui/Title";
import s from './styles.module.scss'
import {VehicleTypesList} from "@/sections/Vehicles/components/VehicleTypesList";
import {APP_URL} from "@/CONSTANTS";
import {RoleType} from "@/interfaces/RoleType";
import {useEffect, useState} from "react";
import {VehiclesTotalCnt} from "@/sections/Vehicles/components/VehiclesTotalCount";
import {VehicleCard} from "@/sections/Vehicles/components/VehicleCard/VehicleCard";
import {useInView} from "react-intersection-observer";
import {mockVehiclesPage1, mockVehiclesPage2, mockVehiclesPage3} from "@/sections/Vehicles/constants";
import {RedirectLink} from "@/ui/RedirectLink/RedirectLink";

interface IProps {
    roleType: RoleType
}

const INITIAL_CNT = 2
const MAX_CNT = 3
const INTERVAL = 30000
const LIMIT = 8

export const Vehicles = ({roleType}: IProps) => {
    const [cnt, setCnt] = useState(INITIAL_CNT)
    const [vehicles, setVehicles] = useState<any[]>(mockVehiclesPage2)

    const {ref, inView} = useInView({triggerOnce: true})

    // const {
    //     data,
    //     isValidating: isFirstPageLoading,
    // } = useSWR<ISuccessResponse<IPaginatedItems<IVehicle>>>(API_URL + `/vehicles/public${formatUrlGetParamsFromObject({page: 1})}`, fetcher, {
    //     revalidateIfStale: false,
    //     revalidateOnFocus: false,
    // })

    const [animatedCnt, setAnimatedCnt] = useState(0)

    useEffect(() => {
        if (inView && animatedCnt < LIMIT) {
            const timer = setTimeout(() => {

                setVehicles(prev => [{
                    ...mockVehiclesPage1[animatedCnt],
                    isNew: true,
                    created_at: {
                        datetime: new Date().toISOString(),
                    }
                }, ...prev.slice(0, -1)])
                setAnimatedCnt(animatedCnt + 1)
            }, INTERVAL)

            return () => clearTimeout(timer)
        }
    }, [animatedCnt, inView])

    // const {
    //     data: pageData,
    //     error,
    //     isValidating,
    // } = useSWR<ISuccessResponse<IPaginatedItems<IVehicle>>>(API_URL + `/vehicles/public${formatUrlGetParamsFromObject({page: cnt})}`, fetcher, {
    //     revalidateIfStale: false,
    //     revalidateOnFocus: false,
    // })

    // useEffect(() => {
    //     if (!pageData) return
    //     setVehicles([...vehicles, ...pageData.data.items])
    // }, [pageData])

    const handleLoadMore = (cnt: number) => {
        if (cnt === MAX_CNT) {
            localStorage.setItem('profileType', roleType)
            window.open(`${APP_URL}/sign-up`, '_blank')
        } else {
            setCnt(cnt + 1)
            setVehicles(prev => ([...prev, ...mockVehiclesPage3]))
        }
    }


    return (
        <section className={classNames(s.container, 'container')} id={'vehicles'} ref={ref}>

            <div className={classNames(styles.flex)}>
                <div className={classNames(styles.titleBlock)}>
                    <Title
                        as={'h3'}
                        type={'section'}
                        style={{textAlign: 'start'}}
                        className={classNames(styles.title)}
                    >
                        Техника
                    </Title>

                    <VehiclesTotalCnt roleType={roleType}
                        // data={data}
                        // isLoading={isFirstPageLoading}
                                      totalCnt={animatedCnt}/>
                </div>
                <RedirectLink href={`${APP_URL}/sign-up`}
                              className={classNames(styles.button, styles.desktop)}
                              style={{background: `var(--primary-executor-color)`}}
                              text={'Добавить технику'}
                              roleType={roleType}
                />
            </div>
            <VehicleTypesList roleType={roleType}/>

            <div className={classNames(s.grid)}>
                {vehicles.map(item => <VehicleCard key={item.id} item={item} roleType={roleType}
                                                   isNew={item?.isNew} isMock={true}/>)}

                {/*{isValidating && <VehiclesPageSkeleton/>}*/}

                {/*{error && <div>failed to load</div>}*/}
            </div>

            <button
                className={classNames(styles.moreButton)}
                style={{
                    border: `1px solid var(--primary-${roleType}-color)`,
                    color: `var(--primary-${roleType}-color)`
                }}
                onClick={() => handleLoadMore(cnt)}
            >
                Показать ещё
            </button>
        </section>
    )
}