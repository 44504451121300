import {CSSProperties} from 'react'

import s from './styles.module.scss'
import classNames from "classnames";

interface IProps {
    width?: number | string
    height?: number | string
    borderRadius?: number
    style?: CSSProperties
    className?: string
}


export const BaseSkeletonLoader = ({
                                       width = '100%',
                                       height = '100%',
                                       borderRadius = 10,
                                       style,
                                       className,
                                   }: IProps) => {
    const sWidth = typeof width === 'string' ? width : `${width}px`
    const sHeight = typeof height === 'string' ? height : `${height}px`
    return (
        <div
            style={{width: `${sWidth}`, height: `${sHeight}`, borderRadius: `${borderRadius}px`, ...style}}
            className={classNames(s.skeleton, className)}
        >
            <div className={s.skeletonInner}/>
        </div>
    )
}