'use client'
import {Title} from "@/ui/Title";
import classNames from "classnames";
import styles from './styles.module.scss'
import {SliderCarousel} from "@/sections/Beginning/components/SliderCarousel";
import {MobileCards} from "@/sections/Beginning/components/MobileCards";
import {useResizeWindow} from "@/hooks/useResizeWindow";
import {useEffect, useState} from "react";


interface IProps {
  roleType: 'customer' | 'executor'
}
export const Beginning = ({roleType}: IProps) => {
  const [isClient, setIsClient] = useState(false)

  const width  = useResizeWindow()


  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <section className={classNames(styles.container, 'container')} id={'how-to-start'}>
      <Title
        as={'h4'}
        type={'section'}
        style={{textAlign: 'start'}}
        className={classNames(styles.title)}
      >
        Как начать работать?
      </Title>
      {isClient &&
        <>
          {width && width > 1240 && <SliderCarousel roleType={roleType} width={width}/>}
          {width && width <= 1240 && <MobileCards roleType={roleType} width={width}/>}
        </>
      }
    </section>
  )
}
