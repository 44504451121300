import {TaskCard} from "@/sections/NewTasks/components/TaskCard";
import s from './styles.module.scss'
import {IVehicle} from "@/interfaces/IVehicle/IVehicle";
import {RoleType} from "@/interfaces/RoleType";
import {Characteristics} from "@/interfaces/IVehicle/ICharacteristic";
import moment from "moment";
import {declensionAfterNumerals} from "@/helpers/declensionAfterNumerals";


interface IVehicleCardProps {
    item: IVehicle & { isNew?: boolean }
    roleType: RoleType
    isNew?: boolean
    isMock: boolean
}

const roundHour = (h: number) => {
    if (h % 3 === 0) return h
    else {
        let newH = h

        while (newH % 3 !== 0) {
            newH = newH + 1
        }

        return newH
    }
}

const getTime = (dateTime?: string) => {
    if (!dateTime) return 'Только что'

    const sliced = dateTime.slice(0, -1)

    const diffDays = moment().diff(moment(sliced), "days")
    if (diffDays > 30) return 'Месяц назад'
    if (diffDays >= 1) return `${diffDays} ${declensionAfterNumerals(diffDays, ['день', 'дня', 'дней'])} назад`

    const diffMinutes = moment().diff(moment(sliced), "minutes")
    if (diffMinutes < 30) return 'Только что'

    const diffHours = moment().diff(moment(sliced), "hours")
    if (diffHours === 1) return 'Час назад'
    if (diffHours > 21) return '1 день назад'

    const roundedDiffHours = roundHour(diffHours)
    return `${roundedDiffHours} ${declensionAfterNumerals(roundedDiffHours, ['час', 'часа', 'часов'])} назад`
}

const getImage = (gallery: IVehicle['image_gallery']) => {
    if (!gallery || (gallery && !gallery.length)) return

    const primaryImage = gallery.find(item => item.is_primary)
    const nonPrimaryImage = gallery[0]

    return primaryImage ?? nonPrimaryImage
}

const getCharacteristicValue = (c: Characteristics) => {
    const value = c.value

    if (!value) return ''

    if (typeof value === 'number') {

        if ('list' in c && c?.list?.length) {
            const v = c.list.find(item => item.id === value)?.name
            return v ?? ''
        } else return `${c.value} ${c.unit.name}`

    } else if (Array.isArray(value)) {
        // @ts-ignore
        return `${c.value().join(', ')} ${c.unit.name}`
    } else if ('min' in value) {
        return `${value.min}-${value.max} ${c.unit.name}`
    }
}

const charNames = ['объем', 'высота', 'грузоподъемность']

const getCharacteristics = (configurations: IVehicle['configurations']) => {
    const c = configurations[0]

    return c.characteristics.filter(item => charNames.some(elem => elem === item.name.toLowerCase()))
        .map(item => ({name: item.name, value: getCharacteristicValue(item)}))
}

const getMockCharacteristics = (item: any) => {
    const characteristics = []

    if (item?.capacity) {
        characteristics.push({name: 'Грузоподъемность', value: item.capacity})
    }
    if (item?.volume) {
        characteristics.push({name: 'Объем', value: item.volume})
    }
    if (item?.liftingHeight) {
        characteristics.push({name: 'Высота ', value: item.liftingHeight})
    }

    return characteristics
}

export const VehicleCard = ({item, roleType, isNew = false, isMock}: IVehicleCardProps) => {
    const image = getImage(item?.image_gallery)

    const task = isMock ? {
            id: item.id,
            // @ts-ignore
            vehicleType: item.title,
            // @ts-ignore
            modelName: item.model,
            // @ts-ignore
            taskType: item.workTypes,
            location: 'Саратовская область',
            vehicleCharacteristics: getMockCharacteristics(item),
            isNewVehicle: isNew,
            photos: [],
            created_at: item?.created_at,
        }
        : {
            vehicleType: `${item.configurations.map(item => `${item.vehicle_type.name}/${item.name}`).join(', ')}`,
            modelName: `${item.brand.name}/${item.name}`,
            taskType: `${item.task_types.map(item => item.name).join(', ')}`,
            location: item.regions ? `${item.regions.map(item => `${item.name} ${item.type}`).join(', ')}` : null,
            photos: image ? [{
                public_url: image.media.public_url
            }] : [],
            created_at: item?.created_at,
            vehicleCharacteristics: getCharacteristics(item.configurations).filter(item => item.value),
            isNewVehicle: isNew
        }

    return <TaskCard className={s.card} cardType={'vehicle'} roleType={roleType} task={task} isMock={isMock}/>
}