import img1 from './mockImages/1.jpg'
import img2 from './mockImages/2.jpg'
import img3 from './mockImages/3.jpg'
import img4 from './mockImages/4.jpg'
import img5 from './mockImages/5.jpg'
import img6 from './mockImages/6.png'
import img7 from './mockImages/7.png'
import img8 from './mockImages/8.png'
import img9 from './mockImages/9.jpg'
import img10 from './mockImages/10.jpg'
import img11 from './mockImages/11.jpg'
import img12 from './mockImages/12.jpg'
import img14 from './mockImages/14.jpg'
import img18 from './mockImages/18.webp'
import img19 from './mockImages/19.jpg'
import img20 from './mockImages/20.jpg'
import img23 from './mockImages/23.jpg'
import img25 from './mockImages/25.jpg'
import img26 from './mockImages/26.jpeg'
import img27 from './mockImages/27.jpeg'
import img29 from './mockImages/29.jpg'
import img30 from './mockImages/30.jpg'
import img33 from './mockImages/33.jpg'
import img35 from './mockImages/35.jpg'
import img38 from './mockImages/38.webp'
import img39 from './mockImages/39.webp'
import img41 from './mockImages/41.jpeg'
import img42 from './mockImages/42.webp'
import img43 from './mockImages/43.jpeg'
import img44 from './mockImages/44.jpeg'
import img45 from './mockImages/45.jpeg'
import img46 from './mockImages/46.jpeg'
import img49 from './mockImages/49.png'

const handleConvertTime = (minutes: number) => {
  let currentDate = new Date();

  currentDate.setMinutes(currentDate.getMinutes() - minutes);

  return currentDate.toISOString()
}

const handleConvertDate = (dateValue: string) => {
  const dateParts = dateValue.split('.')
  const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)

  return date.toISOString()
}



export const mockDataApplications = [
  {
    id: 1,
    title: 'Прокопать траншею',
    description: 'Экскаватор прокопать траншею на глубину фундамента для тех.коммуникаций  90 см',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('05.05.2024')}`},
    image: img1
  },
  {
    id: 2,
    title: 'Демонтаж',
    description: 'Демонтировать корпус цеха',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2200',
    photo: true,
    created_at: {datetime: `${handleConvertDate('06.05.2024')}`},
    image: img2
  },
  {
    id: 3,
    title: 'Минипогрузчик',
    description: 'Засыпать дорогу боем бетона 300 кубов',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2300',
    photo: true,
    created_at: {datetime: `${handleConvertDate('29.04.2024')}`},
    image: img3
  },
  {
    id: 4,
    title: 'Кран',
    description: 'Нужен манипулятор разобрать кран-балку',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '3000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('07.05.2024')}`},
    image: img4
  },
  {
    id: 5,
    title: 'Перевозка',
    description: 'Трал перевезти бульдозер(не на ходу)',
    type: {
      name: 'Грузоперевозка'
    },
    cost: null,
    photo: true,
    created_at: {datetime: `${handleConvertDate('01.04.2024')}`},
    image: img5
  },
  {
    id: 6,
    title: 'Чистка снега',
    description: 'Экскаватор погрузчик вывезти снег на фото',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('01.02.2024')}`},
    image: img6
  },
  {
    id: 7,
    title: 'Кирпич красный',
    description:  'Кирпич красный м150  полнотелый 20 паллетов',
    type: {
      name: 'Доставка материала'
    },
    cost: '6000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('06.05.2024')}`},
    image: img7
  },
  {
    id: 8,
    title: 'Манипулятор',
    description:  'Переместить блоки ФБС по участку ',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('03.05.2024')}`},
    image: img8
  },
  {
    id: 9,
    title: 'Авария!',
    description:  'Манипулятор/кран вытащить бобкет',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('25.04.2024')}`},
    image: img9
  },
  {
    id: 10,
    title: 'Доставить изделие',
    description:  'Забрать изделие перевезти и установить',
    type: {
      name: 'Грузоперевозка'
    },
    cost: null,
    photo: true,
    created_at: {datetime: `${handleConvertDate('30.04.2024')}`},
    image: img10
  },
  {
    id: 11,
    title: 'Вывоз мусора',
    description:  'Нужен Камаз вывезти строительный мусор',
    type: {
      name: 'Грузоперевозка'
    },
    cost: '4500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('08.05.2024')}`},
    image: img11
  },
  {
    id: 12,
    title: 'Планировка',
    description:  'Расспланировать бой кирпича по дороге и немного подробить',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('02.05.2024')}`},
    image: img12
  },
  {
    id: 13,
    title: 'Кран',
    description:  'Поднять профлист на крышу',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '3200',
    photo: false,
    video: true,
    created_at: {datetime: `${handleConvertDate('24.04.2024')}`},
  },
  {
    id: 14,
    title: 'Контейнер',
    description:  'Трал или длинномер перевезти контейнер',
    type: {
      name: 'Грузоперевозка'
    },
    cost: null,
    photo: true,
    created_at: {datetime: `${handleConvertDate('27.04.2024')}`},
    image: img14
  },
  {
    id: 15,
    title: 'Автовышка',
    description:  'Поменять лампы на дорожных осветительных столбах',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '1500',
    photo: false,
    created_at: {datetime: `${handleConvertDate('11.04.2024')}`},
  },
  {
    id: 16,
    title: 'Песок речной',
    description:  '10 кубов речного песка(КАМАЗ с ЗАДНИМ БОРТОМ!!!!)',
    type: {
      name:  'Доставка материала'
    },
    cost: '10000',
    photo: false,
    created_at: {datetime: `${handleConvertDate('14.04.2024')}`},
  },
  {
    id: 17,
    title: 'Манипулятор',
    description:  'Перевезти КУНГ негабарит 2.55 на 4 метра',
    type: {
      name:  'Грузоперевозка'
    },
    cost: '13000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('25.04.2024')}`},
  },
  {
    id: 18,
    title: 'Экскаватор-погрузчик',
    description:  'Вырать три ямы под септик ',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '2500',
    photo: false,
    created_at: {datetime: `${handleConvertDate('22.04.2024')}`},
    image: img18
  },
  {
    id: 19,
    title: 'Автовышка 18м',
    description:  'Смонтировать сендвич панели на ангар',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '1500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('15.04.2024')}`},
    image: img19
  },
  {
    id: 20,
    title: 'Длинномер с конниками',
    description:  'Перевезти ригеля Саратов-Энгельс',
    type: {
      name:  'Грузоперевозка'
    },
    cost: '10000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('16.04.2024')}`},
    image: img20
  },
  {
    id: 21,
    title: 'Автокран',
    description:  'Поднять материал на крышу частного дома',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '2500',
    photo: false,
    created_at: {datetime: `${handleConvertDate('04.04.2024')}`},
  },
  {
    id: 22,
    title: 'Бобкет с ямобуром',
    description:  'Пробурить 20 лунок, 700-г 200-d',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '2500',
    photo: false,
    created_at: {datetime: `${handleConvertDate('09.04.2024')}`},
  },
  {
    id: 23,
    title: 'Гидромолот',
    description:  'Демонтировать гараж',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '3000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('06.05.2024')}`},
    image: img23
  },
  {
    id: 24,
    title: 'Миниавтобус',
    description:  'Группу из 14 человек отвезти в субботу утром в СНТ ВОЛГА в воскресенье в город',
    type: {
      name:  'Пассажироперевозки'
    },
    cost: '14000',
    photo: false,
    created_at: {datetime: `${handleConvertDate('08.04.2024')}`},
  },
  {
    id: 25,
    title: 'Манипулятор',
    description:  'Перевезти ковш в Сазанку',
    type: {
      name:  'Грузоперевозки'
    },
    cost: '7000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('07.04.2024')}`},
    image: img25
  },
  {
    id: 26,
    title: 'Экскаватор-погрузчик',
    description:  'Планировка участка',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '2500',
    photo: true,
    created_at: {datetime: `${handleConvertDate('08.05.2024')}`},
    image: img26
  },
  {
    id: 27,
    title: 'Бортовой КАМАЗ',
    description:  'ПЕРЕВЕЗТИ!!!5 штук 2-yx скатные длинна 12 метров вес одной 900кг',
    type: {
      name:  'Грузоперевозки'
    },
    cost: '5000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('28.04.2024')}`},
    image: img27
  },
  {
    id: 28,
    title: 'Доставить ПГС',
    description:  'В село Беленькие Требуется 40 кубов песко-гравийной смеси',
    type: {
      name:  'Доставка материала'
    },
    cost: '10000',
    photo: false,
    created_at: {datetime: `${handleConvertDate('31.03.2024')}`},
  },
  {
    id: 29,
    title: 'Манипулятор 7т ',
    description:  'Демонтаж конструкций Энгельс проспект строителей ',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '3000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('10.05.2024')}`},
    image: img29
  },
  {
    id: 30,
    title: 'Телескопический погрузчик',
    description:  'На летний период в КФХ требуется телескопический погрузчик, как на фото',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: null,
    photo: true,
    created_at: {datetime: `${handleConvertDate('15.04.2024')}`},
    image: img30
  },
  {
    id: 31,
    title: 'Илосос/Ассинизатор',
    description:  'Вычистить выгребную яму 3 куба',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '3500',
    photo: false,
    created_at: {datetime: `${handleConvertDate('18.04.2024')}`},
  },
  {
    id: 32,
    title: 'Миниэкскаватор',
    description:  'ПРОКАПАТЬ УЧАСТОК (ШУМЕЙКА)',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '2000',
    photo: false,
    created_at: {datetime: `${handleConvertDate('25.04.2024')}`},
  },
  {
    id: 33,
    title: 'Экскаватор-погрузчик',
    description:  'Авария!!!! Выкопать трубy затем после ремонта закопать обратно',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '3000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('12.05.2024')}`},
    image: img33
  },
  {
    id: 34,
    title: 'Бобик с щеткой',
    description:  'почистить стройплощадку',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '2400',
    photo: false,
    created_at: {datetime: `${handleConvertDate('11.04.2024')}`},
  },
  {
    id: 35,
    title: 'Кран 31м ',
    description:  'Поставить пять электростолбов',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '3000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('29.04.2024')}`},
    image: img35
  },
  {
    id: 36,
    title: 'Автовышка 22м',
    description:  'Покрасить фасад ангара, после работы все отмоем',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '1800',
    photo: false,
    created_at: {datetime: `${handleConvertDate('13.04.2024')}`},
  },
  {
    id: 37,
    title: 'Отчистить парковку',
    description:  'Джисиби или бобкет почистить парковку в Юбилейном',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '2500',
    photo: false,
    created_at: {datetime: `${handleConvertDate('17.04.2024')}`},
  },
  {
    id: 38,
    title: 'Ломовоз',
    description:  'Вывезти мусор ',
    type: {
      name:  'Аренда спецтехники'
    },
    cost: '10000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('11.05.2024')}`},
    image: img38
  },
  {
    id: 39,
    title: 'Манипулятор',
    description:  'Превезти контейнер',
    type: {
      name:  'Грузоперевозки'
    },
    cost: '8000',
    photo: true,
    created_at: {datetime: `${handleConvertDate('19.01.2024')}`},
    image: img39
  },
  {
    id: 40,
    title: 'Ершовский щебень',
    description:  '100 кубов щебня 20х40',
    type: {
      name:  'Доставка материала'
    },
    cost: null,
    photo: false,
    created_at: {datetime: `${handleConvertDate('06.05.2024')}`},
  },
  {
    id: 41,
    title: 'АГП',
    description:  'Отпескоструить металлический ангар, телескоп, вышка с люлькой как на фото',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '1200',
    created_at: {datetime: `${handleConvertDate('09.05.2024')}`},
    image: img41
  },
  {
    id: 42,
    title: 'Автокран 25м',
    description:  'Нс стройку требуется кран поднимать материал на этажи, 5 смен',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '2000',
    created_at: {datetime: `${handleConvertDate('06.05.2024')}`},
    image: img42
  },
  {
    id: 43,
    title: 'Манипулятор',
    description:  'Отвезти фбски в Тепловку',
    type: {
      name: 'Грузоперевозки'
    },
    cost: '12000',
    created_at: {datetime: `${handleConvertDate('18.04.2024')}`},
    image: img43
  },
  {
    id: 44,
    title: 'Миниэкскаватор 5т',
    description: 'Выкопать ленточный фундамент',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '1500',
    created_at: {datetime: `${handleConvertDate('14.03.2024')}`},
    image: img44
  },
  {
    id: 45,
    title: 'Ломовоз',
    description: 'Вывезти мусор',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '10000',
    created_at: {datetime: `${handleConvertDate('04.05.2024')}`},
    image: img45
  },
  {
    id: 46,
    title: 'Манипулятор',
    description: 'переместить станок внутри цеха',
    type: {
      name: 'Грузоперевозка'
    },
    cost: '8000',
    created_at: {datetime: `${handleConvertDate('12.04.2024')}`},
    image: img46
  },
  {
    id: 47,
    title: 'Самосвал 15т',
    description: '10т речной песок 5 тонн щебень м800 20х40',
    type: {
      name: 'Доставка материала'
    },
    cost: null,
    created_at: {datetime: `${handleConvertDate('07.05.2024')}`},
    image: null
  },
  {
    id: 48,
    title: 'Бункеровоз',
    description: 'Аренда бункера на стройку на 3 дня',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: '13000',
    created_at: {datetime: `${handleConvertDate('11.04.2024')}`},
    image: null
  },
  {
    id: 49,
    title: 'Грейдер',
    description: 'Проработать дорогу 400м в Шевыревке',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: null,
    created_at: {datetime: `${handleConvertDate('12.05.2024')}`},
    image: img49
  },
  {
    id: 49,
    title: 'Бетононасос',
    description: '24 м трасса, 9 кубов бетона прокачать',
    type: {
      name: 'Аренда спецтехники'
    },
    cost: null,
    created_at: {datetime: `${handleConvertDate('26.04.2024')}`},
    image: null
  },
]
