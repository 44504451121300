import s from './styles.module.scss'
import {APP_URL} from '@/CONSTANTS'
import {RoleTypeExtended} from '@/interfaces/RoleType'
import {RedirectLink} from "@/ui/RedirectLink/RedirectLink";

interface IProps {
    roleType: RoleTypeExtended
    colorType?: RoleTypeExtended
}

export const Login = ({roleType, colorType}: IProps) => {
    return <div className={s.wrapper}>
        <RedirectLink href={`${APP_URL}/sign-up`}
                      className={`${s.registration} ${s.button}`}
                      style={{color: `var(--primary-${colorType ?? roleType}-color)`}}
                      roleType={roleType}
                      text={'Регистрация'}
        />

        <RedirectLink href={`${APP_URL}/sign-in`}
                      className={`${s.login} ${s.button}`}
                      roleType={roleType}
                      text={'Войти'}
        />
    </div>
}