import { createElement, CSSProperties, ReactNode } from 'react'
import s from './styles.module.scss'

interface IProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: ReactNode
  type: 'page' | 'section' | 'subsection'
  className?: string
  style?: CSSProperties
}

export const Title = ({ as, children, style, className, type, ...props }: IProps) => {
  return createElement(as, {
    className: `${s[`${type}-title`]} ${className ?? ''}`,
    style,
    ...props,
  }, children)
}