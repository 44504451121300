'use client'
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import BitrixForm from '@/sections/Main/components/BitrixForm';
import s from './DescriptionRow/styles.module.scss'


interface IProps {
  style?: React.CSSProperties;
}

const BitrixFormMobile: React.FC<IProps> = ({style}) => {
  const [formVisible, setFormVisible] = useState(false);
  const formRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setFormVisible(true);
  };

  const closeModal = () => {
    setFormVisible(false);
  };

  useEffect(() => {
    if (formVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [formVisible]);

  const Modal = () => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          width: '100%',
          height: '100%',
          padding: '40px 0',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        ref={formRef}
      >
        <button
          style={{
            position: 'absolute',
            padding: '5px 12px',
            top: '0',
            right: '10px',
            background: 'transparent',
            border: 'none',
            fontSize: '2.5rem',
            cursor: 'pointer',
            zIndex: 1,
          }}
          onClick={closeModal}
        >
          &times;
        </button>
        <div style={{margin: '20px 0'}}>
        <BitrixForm />
          </div>
        <button onClick={closeModal}
                style={{
                  backgroundColor: 'var(--primary-customer-color)',
                  marginTop: 10,
                  width: '100%',
                  maxWidth: '386px',
                  borderRadius: 5,
                  padding: 18,
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '.95rem',
        }}
                className={classNames( s.linkCustomer)}
        >
          Вернуться на главную
        </button>
      </div>
    </div>
  );

  return (
    <>
      <button
        className={classNames(s.link, s.linkCustomer)}
        style={style}
        onClick={handleClick}
      >
        Создать заявку
      </button>

      {formVisible && typeof window !== 'undefined' && createPortal(<Modal />, document.body)}
    </>
  );
};

export default BitrixFormMobile;
