'use client'

import {Title} from "@/ui/Title";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {TaskCard} from "@/sections/NewTasks/components/TaskCard";

import {LoadCards} from "@/sections/Vehicles/helpers/LoadCards";
import {API_URL} from "@/CONSTANTS";
import {useEffect, useState} from "react";

import useSWR from "swr";
import {fetcher} from "@/api/fetcher";
import {BaseSkeletonLoader} from "@/ui/BaseSkeletonLoader/BaseSkeletonLoader";
import {declensionAfterNumerals} from "@/helpers/declensionAfterNumerals";

import s from "@/sections/Vehicles/styles.module.scss";
import {mockDataApplications} from "@/sections/NewTasks/constants";

interface IProps {
  roleType: 'customer' | 'executor'
}
export const NewTasks = ({roleType}: IProps) => {
  const [data, setData] = useState<Record<string, any>[]>([])
  const [totalItemsQuantity, setTotalItemsQuantity] = useState(0)
  const [successOrder, setSuccessOrder] = useState<any[]>([])

  const filterMockArr = mockDataApplications.sort((a, b) => new Date(b.created_at.datetime).getTime() - new Date(a.created_at.datetime).getTime())

  // const PageDataFetcher = (page: number) => {
  //   const { data } = useSWR<any>(
  //     API_URL + `/tasks/public?page=${page}`,
  //     fetcher,
  //     {
  //       revalidateIfStale: false,
  //       revalidateOnFocus: false,
  //     }
  //   );
  //
  //   return data;
  // };

  // const dataItems = PageDataFetcher(1)
  // const dataItems2 = PageDataFetcher(2)
  // const dataItems3 = PageDataFetcher(3)

  const {
    displayedItems,
    handleShowMoreItems,
    newItemsCounter,
    handleCreateOrder
  } = LoadCards(
    {
      initialArr: filterMockArr,
      visibleItemsCounter: 8,
        roleType
    }
  )



  // useEffect(() => {
  //   if (successOrder.includes('dataItems') && successOrder.includes('dataItems2') && successOrder.includes('dataItems3')) {
  //     const arr = [...dataItems2.data.items, ...dataItems3.data.items, ...dataItems.data.items]
  //     setData(arr)
  //   }
  // }, [successOrder])
  //
  // useEffect(() => {
  //   if (dataItems) {
  //     setSuccessOrder(prevOrder => [...prevOrder, 'dataItems']);
  //   }
  // }, [dataItems])
  //
  // useEffect(() => {
  //   if (dataItems2) {
  //     setSuccessOrder(prevOrder => [...prevOrder, 'dataItems2']);
  //   }
  // }, [dataItems2])
  //
  // useEffect(() => {
  //   if (dataItems3) {
  //
  //     setSuccessOrder(prevOrder => [...prevOrder, 'dataItems3']);
  //   }
  // }, [dataItems3])

  console.log(displayedItems, 'displayedItems')

  return (
    <section className={classNames(styles.container, 'container')} id={'tasks'}>
      <div className={classNames(styles.flex)}>
        <div className={classNames(styles.titleBlock)}>
          <Title
            as={'h3'}
            type={'section'}
            style={{textAlign: 'start'}}
            className={classNames(styles.title)}
          >
            Новые заказы
          </Title>
          {totalItemsQuantity !== null
            ? <div
              className={classNames(styles.preview)}
              style={{background: `var(--primary-${roleType}-color)`}}
            >
              {`${731 + newItemsCounter} 
              ${declensionAfterNumerals(731 + newItemsCounter, ['объявление', 'объявления', 'объявлений'])} за день`}
            </div>
            : <BaseSkeletonLoader width={252} height={54} className={classNames(styles.preview)}/>
          }
        </div>
        <button
          aria-label="Создать заказ"
          onClick={handleCreateOrder}
          className={classNames(styles.button, styles.desktop)}
          style={{ background: `var(--primary-${roleType}-color)`}}
        >
          Разместить заказ
        </button>
      </div>

      <div
        className={classNames(s.grid)}
        style={{
          marginTop: '40px'
        }}
      >
        {displayedItems.map((el, index) => <TaskCard key={index} task={el} roleType={roleType} cardType={'task'}/>)}
      </div>
      <button
        aria-label="Показать ещё"
        className={classNames(styles.moreButton)}
        style={{
          border: `1px solid var(--primary-${roleType}-color)`,
          color: `var(--primary-${roleType}-color)`
        }}
        onClick={handleShowMoreItems}
      >
        Показать ещё
      </button>
      <button
        aria-label="Разместить заказ"
        className={classNames(styles.button, styles.mobile)}
        style={{ background: `var(--primary-${roleType}-color)`}}
        onClick={handleCreateOrder}
      >
        Разместить заказ
      </button>
    </section>
  )
}
