import {CSSProperties, ReactNode} from "react";
import classNames from "classnames";
import s from './styles.module.scss'

interface ITextProps {
    children: ReactNode
    className?: string
    style?: CSSProperties
}

export const RegularText = ({children, className, style}: ITextProps) => {
    return <p className={classNames(s.text, className)} style={style}>{children}</p>
}
