import {beginningArray} from "@/sections/Beginning/constants";
import {Slider as SliderComponent} from "@/sections/Beginning/components/Slider";
import classNames from "classnames";
import s from '../styles.module.scss'
import {useEffect, useState} from "react";

interface IProps {
  roleType: 'executor' | 'customer'
  width?: number
}
export const MobileCards = ({roleType, width}: IProps) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <>
      {isClient &&
        <div className={classNames(s.mobileFlex)}>
          {beginningArray.map((el, index) => {
            return (
              <SliderComponent key={index} el={el} roleType={roleType} index={index} width={width} />
            )
          })}
        </div>
      }
    </>
  )
}
