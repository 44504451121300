import {useEffect, useState} from "react";
import {APP_URL} from "@/CONSTANTS";
import {RoleTypeExtended} from "@/interfaces/RoleType";

interface IProps {
  initialArr: Record<string, any>[]
  visibleItemsCounter: number
  type?: string
  roleType: RoleTypeExtended
}

export const LoadCards = ({initialArr, visibleItemsCounter, type, roleType}: IProps) => {
  const [clickedCounter, setClickedCounter] = useState(0)
  const [visibleItems, setVisibleItems] = useState(visibleItemsCounter);
  const [newItemsCounter, setNewItemsCounter] = useState(0)
  const [displayedItems, setDisplayedItems] = useState<Record<string, any>[]>([]);
  const [addedCardIds, setAddedCardIds] = useState<number[]>([]);

  let ACCESS_TOKEN: any
  if (typeof window !== 'undefined') {
    ACCESS_TOKEN = localStorage.getItem('ACCESS_TOKEN');
  }

  let ownSpaceId: any
  if (typeof window !== 'undefined') {
    ownSpaceId = localStorage.getItem('ownSpaceId')
  }

  const handleShowMoreItems = () => {
    if(clickedCounter === 1) {
      if(ACCESS_TOKEN && ownSpaceId) {
        localStorage.setItem('profileType', 'executor')
        window.open(`${APP_URL}/app/space/${ownSpaceId}/tasks`, '_blank')
      } else {
        window.open(`${APP_URL}/sign-up`, '_blank')
        localStorage.setItem('profileType', roleType)
      }
    } else {
      setClickedCounter((prevState) => prevState + 1 )
      setVisibleItems( prevState => prevState + visibleItemsCounter)
    }
  }


  const handleCreateOrder = () => {
    if(ACCESS_TOKEN && ownSpaceId) {
      localStorage.setItem('profileType', 'customer')
      window.open(`${APP_URL}/app/space/${ownSpaceId}/myApplications`, '_blank')
    } else {
      localStorage.setItem('profileType', roleType)
      window.open(`${APP_URL}/sign-up`, '_blank')
    }
  }

  useEffect(() => {
    if(displayedItems.length < initialArr.length) {
      const intervalId = setInterval(() => {
        const unusedVehicles = initialArr.filter(item => !addedCardIds.includes(item.id));

        if (unusedVehicles.length > 0) {
          const newCard = unusedVehicles[unusedVehicles.length - 1];
          setAddedCardIds(prevIds => [...prevIds, newCard.id]);

          setDisplayedItems(prevItems => {
            const updatedItems = prevItems.map(item => ({
              ...item,
              isNew: false,
            }));
            return [
              { ...newCard, isNew: true, created_at: { datetime: new Date().toISOString()} },
              ...updatedItems.slice(0, visibleItems - 1),
            ];
          });

          setNewItemsCounter(newItemsCounter + 1)
        } else {
          clearInterval(intervalId);
        }
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [addedCardIds, displayedItems, initialArr]);

  useEffect(() => {
    if(visibleItems !== displayedItems.length) {
      const items = initialArr
        .filter((item) => !addedCardIds.includes(item.id))
        .slice(0, visibleItemsCounter);

      const newIds = items.map((el) => el.id)
      setAddedCardIds((prevIds) => [...prevIds, ...newIds]);

      if( visibleItems > 9) {
        setDisplayedItems([...displayedItems, ...items ]);
      } else {
        setDisplayedItems([ ...items, ...displayedItems]);
      }
    }
  }, [visibleItems, initialArr, visibleItemsCounter]);


  return {
    displayedItems,
    handleShowMoreItems,
    newItemsCounter,
    clickedCounter,
    handleCreateOrder
  }
}
