'use client'

import s from './styles.module.scss'
import Link from 'next/link'
import {APP_URL} from "@/CONSTANTS";
import {RedirectLink} from "@/ui/RedirectLink/RedirectLink";
import classNames from "classnames";
import { useResizeWindow } from '@/hooks/useResizeWindow'
import BitrixFormMobile from '@/sections/Main/components/BitrixFormMobile'

interface IProps {
    data: string[]
    roleType: 'executor' | 'customer'
}

export const DescriptionRow = ({data, roleType}: IProps) => {
    const textMap = {
        customer: 'Для исполнителей',
        executor: 'Для заказчиков',
    }
    const linkMap = {
        customer: '/executor',
        executor: '/',
    }
    const colorMap = {
        customer: 'executor',
        executor: 'customer',
    }

    const deviceWidth = useResizeWindow()
    const isMobile = deviceWidth < 900

    return <div className={s.description}>
        <ul className={s.list}>{data.map(item => {
            return <li key={item}><RedirectLink href={`${APP_URL}/sign-up`} roleType={roleType} text={item}/></li>
        })}</ul>
        {roleType === 'customer' ? isMobile && <BitrixFormMobile style={{backgroundColor: `var(--primary-${colorMap[roleType]}-color)`, marginTop: 10}}/> : <Link href={linkMap[roleType]} className={s.link}
                   style={{backgroundColor: `var(--primary-${colorMap[roleType]}-color)`}}>
            {textMap[roleType]}
        </Link>}

    </div>
}