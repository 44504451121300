import classNames from "classnames";
import styles from "@/sections/NewTasks/styles.module.scss";
import {declensionAfterNumerals} from "@/helpers/declensionAfterNumerals";
import {ISuccessResponse} from "@/api/ISuccessResponse";
import {IPaginatedItems} from "@/interfaces/IPagination";
import {IVehicle} from "@/interfaces/IVehicle/IVehicle";
import {RoleType} from "@/interfaces/RoleType";

export const VehiclesTotalCnt = ({roleType, isLoading, data, totalCnt}: {
    roleType: RoleType,
    isLoading?: boolean,
    totalCnt: number,
    data?: ISuccessResponse<IPaginatedItems<IVehicle>>
}) => {

    // if (isLoading) return <BaseSkeletonLoader className={styles.cntLoader}/>

    return <>
        <div
            className={classNames(styles.preview)}
            style={{background: `var(--primary-${roleType}-color)`, right: '0', left: '0'}}
        >
            {`${(185 + totalCnt)} ${declensionAfterNumerals((185 + totalCnt), ['единица', 'единицы', 'единиц'])} за день`}
        </div>
    </>
}