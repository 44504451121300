'use client'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Slider as SliderComponent} from "@/sections/Beginning/components/Slider";
import Slider from "react-slick";
import {beginningArray, executorBeginningArray} from "@/sections/Beginning/constants";
import s from '../styles.module.scss'
import classNames from "classnames";
import {useEffect, useState} from "react";

interface IProps {
  roleType: 'customer' | 'executor'
  width?: number
}
export const SliderCarousel = ({roleType, width}: IProps) => {
  const [allDots, setAllDots] = useState<NodeListOf<Element>>()
  const [isClicked, setIsClicked] = useState(false)
  const [slideCounter, setSlideCounter] = useState(0)

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "button__bar",
    arrows: false,
    beforeChange: (currentSlide: any, nextSlide: any) => {

      setSlideCounter(nextSlide)
      allDots?.forEach((ul, i) => {
        const liElements = Array.from(ul.children).filter((child) => child.tagName === 'LI');

        liElements.forEach((dot, index) => {
          if (index === nextSlide && !isClicked) {
            const findDot =  dot.querySelector('button')
            if(findDot) {
              findDot.style.background = `var(--primary-${roleType}-color)`
            }
          }

          if(index === currentSlide && !isClicked) {
            const findLine =  dot.querySelector('div')
            findLine ? findLine.style.borderColor = `var(--primary-${roleType}-color)` : ''
          }
        });
      })
      setIsClicked(false)
    },
  };

  const settingsWithModules = {
    ...settings,
    dotsClass: classNames(s.button__bar, roleType === 'executor' ? s.executor : s.customer, 'dots')
  };

  const handleClearDots = () => {
    allDots?.forEach((ul, i) => {
      const liElements = Array.from(ul.children).filter((child) => child.tagName === 'LI');
      liElements.forEach((dot, index) => {
        const findDot =  dot.querySelector('button')
        const findDiv =  dot.querySelector('div')
        if(findDot) {
          findDot.style.background = `var(--light-${roleType}-color)`
          findDiv ? findDiv.style.borderColor = `var(--light-${roleType}-color)` : ''
        }
      })
    })
  }

  const handleAddBackgroundForDots = (elIndex: number) => {
    allDots?.forEach((ul, i) => {
      const liElements = Array.from(ul.children).filter((child) => child.tagName === 'LI');
      liElements.forEach((dot, index) => {
        const findDot =  dot.querySelector('button')
        const findDiv =  dot.querySelector('div')
        if(findDot) {
          elIndex >= index ? findDot.style.background = `var(--primary-${roleType}-color)` : ''
          findDiv && elIndex > index  ? findDiv.style.borderColor = `var(--primary-${roleType}-color)` : ''
        }
      })
    })
  }

  const currentArray = roleType === 'executor' ? executorBeginningArray : beginningArray


  useEffect(() => {
    if (allDots && allDots.length) {
      allDots.forEach((ul, index) => {
        const liElements = Array.from(ul.children).filter((child) => child.tagName === 'LI');
        if(typeof document !== 'undefined') {
          liElements.forEach((li, i) => {
            const span = document.createElement('span');
            const div = document.createElement('div');

            div.className = roleType === 'executor' ? s.executor_line : s.customer_line

            span.textContent = currentArray[i].title;

            li.appendChild(span);

            if(i < liElements.length - 1) {
              li.appendChild(div);
            }
          });
        }

      });
    }
  }, [allDots, currentArray]);

  useEffect(() => {
    if(typeof document !== 'undefined') {
      setAllDots(document.querySelectorAll('.dots'))
    }
  }, [])

  useEffect(() => {
    if(slideCounter === 4 && isClicked ) {
      setTimeout(() => {
        handleClearDots()
      }, 3000)
    } else {
      handleClearDots()
      handleAddBackgroundForDots(slideCounter)
    }
  }, [slideCounter, allDots])

  useEffect(() => {
    if(allDots) {
      allDots.forEach((ul, index) => {
        const liElements = Array.from(ul.children).filter((child) => child.tagName === 'LI');
        liElements.forEach((dot, index) => {
          dot.addEventListener('click', () => {
            setIsClicked(true)
            setSlideCounter(index)
          });
        })
      });
    }
  }, [allDots]);



  return (
    <div className={classNames(s.sliderContainer)}>
      <Slider
        {...settingsWithModules}
      >
        {currentArray.map((el, index) => {
          return (
            <SliderComponent key={index} el={el} roleType={roleType} index={index} width={width}/>
          )
        })}
      </Slider>
    </div>

  )
}