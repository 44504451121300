//TODO запилить env
import { PageType } from '@/interfaces/PageType'

export const APP_URL = process.env.NEXT_PUBLIC_APP_URL || 'https://stage.vereskingtech.ru/app'

export const API_URL = 'https://api.veresking.com/api/v1'

export const MAIN_URL = 'https://stage.vereskingtech.ru'

export const DEFAULT_ROLE_TYPE = 'customer'

export const navigationItems = [
  {
    text: 'Заказчику',
    href: '/',
    pageType: 'customer' as PageType,
  },
  {
    text: 'Исполнителю',
    href: '/executor',
    pageType: 'executor' as PageType,
  },
  {
    text: 'Тарифы',
    href: '/tariffs',
    pageType: 'tariffs' as PageType,
  },
  {
    text: 'Партнерство',
    href: '/partner',
    pageType: 'partner' as PageType,
  },
]